import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../store";

const ProgressCircle: React.FC = React.memo(() => {
  const time = useSelector((state: AppState) => state.system.progressTime);
  return (
    <div className="Progress-circle">
      <div className="Progress-circle-inner">
        <span>{time}</span>
      </div>
    </div>
  );
});

export default ProgressCircle;
