import actionCreatorFactory from "typescript-fsa";
import { CountDownState, ModalState, TypeKey } from "../types/systemTypes";

const actionCreator = actionCreatorFactory();
export const systemAction = {
  updateType: actionCreator<TypeKey>("UPDATE_TYPE"),
  updateModal: actionCreator<{ modal: ModalState; add: boolean }>(
    "UPDATE_MODAL"
  ),
  updateCowntDown: actionCreator<CountDownState>("UPDATE_COWNTDOWN"),
  updateProgressTime: actionCreator<number>("UPDATE_PROGRESS_TIME"),
  updateProgressFnished: actionCreator<boolean>("UPDATE_PROGRESS_FINISHED"),
  updateProgressReseted: actionCreator<boolean>("UPDATE_PROGRESS_RESETED"),
};
