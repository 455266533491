import { useDispatch } from "react-redux";
import { systemAction } from "../actions/systemAction";
import { ModalKey } from "../types/systemTypes";

export const useModal = () => {
  const dispatch = useDispatch();
  const open = (modalKey: ModalKey) => {
    dispatch(
      systemAction.updateModal({ modal: { modalKey: modalKey }, add: true })
    );
  };
  const close = (modalKey: ModalKey) => {
    dispatch(
      systemAction.updateModal({ modal: { modalKey: modalKey }, add: false })
    );
  };
  return { open, close };
};
