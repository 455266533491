import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { systemAction } from "../store/actions/systemAction";
import { TypeKey } from "../store/types/systemTypes";

const SelectType: React.FC = React.memo(() => {
  const type = useSelector((state: AppState) => state.system.type);
  const dispatch = useDispatch();
  const handleChangeType = useCallback(
    (type: TypeKey) => {
      dispatch(systemAction.updateType(type));
    },
    [dispatch]
  );
  return (
    <div className="SelectType">
      <h2 className="SelectType-title">コースを選ぼう</h2>
      <div className="SelectType-inner">
        <div
          className={`SelectType-key ${type === "girls" ? "is-selected" : ""}`}
          onClick={() => handleChangeType("girls")}
        >
          女優
        </div>
        <div
          className="SelectType-selector"
          onClick={() => handleChangeType(type === "girls" ? "boys" : "girls")}
        >
          <span
            className={`SelectType-selector-button ${
              type === "girls" ? "" : "is-boys"
            }`}
          ></span>
        </div>
        <div
          className={`SelectType-key ${type === "boys" ? "is-selected" : ""}`}
          onClick={() => handleChangeType("boys")}
        >
          男優
        </div>
      </div>
    </div>
  );
});
export default SelectType;
