import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { scoreReducer } from "./reducers/scoreReducer";
import { systemReducer } from "./reducers/systemReducer";
import { ScoreState } from "./types/scoreTypes";
import { SystemState } from "./types/systemTypes";

export type AppState = {
  system: SystemState;
  score: ScoreState;
};

interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
declare var window: ExtendedWindow;

const composeReduxDevToolsEnhancers =
  (typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middlewares: never[] = [];

const store = createStore(
  combineReducers<AppState>({
    system: systemReducer,
    score: scoreReducer,
  }),
  composeReduxDevToolsEnhancers(applyMiddleware(...middlewares))
);

export default store;
