import { reducerWithInitialState } from "typescript-fsa-reducers";
import { scoreActions } from "../actions/scoreAction";
import { Girl, initialScoreState } from "../types/scoreTypes";

export const scoreReducer = reducerWithInitialState(initialScoreState)
  .case(scoreActions.addScore, (state, payload: Girl) => {
    return Object.assign({}, state, {
      girls: [...state.girls, payload],
    });
  })
  .case(scoreActions.updateQuestions, (state, payload: number) => {
    return Object.assign({}, state, { questions: payload });
  })
  .case(scoreActions.updateCorrect, (state, payload: number) => {
    return Object.assign({}, state, { correct: payload });
  });
