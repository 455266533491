import React, { useState, useCallback, useEffect, useMemo } from "react";
import "../App.scss";
import Progress from "../components/Progress";
import Girl from "../components/Girl";
import Selector from "../components/Selector";
import Result from "../components/Result";
import girlsData from "../data/girls.json";
import boysData from "../data/boys.json";
import Div100vh from "react-div-100vh";
import Score from "./Score";
import Cover from "./Cover";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { systemAction } from "../store/actions/systemAction";
import ReactGA from "../Analytics";
import GirlVideo from "../components/GirlVideo";
import Hint from "../components/Hint";
import Share from "../components/Share";

// Set Context
type girlContent = {
  selectedName: string;
  setSelectedName: React.Dispatch<React.SetStateAction<string>>;
};
type prizeContext = {
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

export const GirlName = React.createContext({} as girlContent);
export const PrizeMessage = React.createContext({} as prizeContext);

export const COUNT_DOWN_TIME = 7;

export type girlObject = {
  id: number;
  name: string;
  videoId?: string;
};

export type boysObject = {
  id: number;
  name: string;
  gid: number;
};

export const NUMBER_OF_CHOICES = 3;

const App: React.FC = React.memo(() => {
  const type = useSelector((state: AppState) => state.system.type);
  const stop = useSelector((state: AppState) => state.system.countDown.stop);
  const { finished, reseted } = useSelector(
    (state: AppState) => state.system.progress
  );
  const { correct, questions } = useSelector((state: AppState) => state.score);
  const modal = useSelector((state: AppState) => state.system.modal);

  const [selectedName, setSelectedName] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const selectData: number[] = useMemo(() => {
    return type === "girls"
      ? girlsData.map((obj: girlObject) => obj.id)
      : boysData.map((obj: boysObject) => obj.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, reseted]);
  // シャッフル
  const [random, setRandom] = useState(0);
  useEffect(() => {
    setRandom(Math.random());
  }, [reseted]);

  const [girlId, setGirlId] = useState(0);

  const shuffleArray = useMemo(() => {
    const copy = selectData.slice();
    const girlIdArray = [...Array(NUMBER_OF_CHOICES)].map(
      () => copy.splice(Math.floor(random * copy.length), 1)[0]
    );
    setGirlId(girlIdArray[0]);
    return girlIdArray;
  }, [selectData, random]);

  const shuffleData: girlObject[] = useMemo(() => {
    const tmp: girlObject[] =
      type === "girls"
        ? shuffleArray.flatMap((val: number) =>
            girlsData.filter((obj: girlObject) => obj.id === val)
          )
        : shuffleArray.flatMap((val: number) =>
            boysData.filter((obj: girlObject) => obj.id === val)
          );
    return tmp;
  }, [type, shuffleArray]);

  const correctGirlObject = useMemo(() => {
    return shuffleData.filter((obj: girlObject) => obj.id === girlId)[0];
  }, [girlId, shuffleData]);
  const girlImage = useMemo(() => {
    return `${process.env.PUBLIC_URL}/image/${type}/${girlId}.jpg`;
  }, [type, girlId]);

  const handleChange = useCallback(() => {
    dispatch(systemAction.updateProgressReseted(true));
    dispatch(systemAction.updateCowntDown({ stop: false }));
  }, [dispatch]);

  useEffect(() => {
    if (reseted) {
      dispatch(systemAction.updateProgressReseted(false));
    }
  }, [reseted, dispatch]);

  // Send Event to Google Analytics
  useEffect(() => {
    if (questions > 0) {
      ReactGA.event({
        category: "Score",
        action: "Questions",
        label: questions.toString(),
      });
    }
  }, [questions]);
  useEffect(() => {
    if (correct > 0) {
      ReactGA.event({
        category: "Score",
        action: "Corrects",
        label: correct.toString(),
      });
    }
  }, [correct]);
  useEffect(() => {
    if (modal.length > 0) {
      ReactGA.event({
        category: "View",
        action: "Modal",
        label: modal[0].modalKey,
      });
    }
  }, [modal]);
  return (
    <div className="Background">
      <div className={`App Layout ${type === "girls" ? "" : "is-boys"}`}>
        <Cover />
        <PrizeMessage.Provider value={{ message, setMessage }}>
          <GirlName.Provider value={{ selectedName, setSelectedName }}>
            <Div100vh>
              <div
                className={`App-content ${finished ? "is-finished" : ""} ${
                  stop ? "is-open-score" : ""
                }`}
              >
                <Progress />
                <Girl image={girlImage} />
                {type === "girls" && <Hint />}
                <Selector data={shuffleData} />
              </div>
              {correctGirlObject && (
                <Result
                  correct={selectedName === correctGirlObject.name}
                  handleChange={handleChange}
                  correctId={Number(girlId)}
                  correctName={correctGirlObject.name}
                />
              )}
            </Div100vh>
          </GirlName.Provider>
          {type === "girls" && (
            <GirlVideo
              videoId={
                correctGirlObject?.videoId
                  ? correctGirlObject.videoId
                  : "mird00141"
              }
            />
          )}
          <Score />
          <Share />
        </PrizeMessage.Provider>
      </div>
    </div>
  );
});

export default App;
