import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "../Analytics";
import { AppState } from "../store";
import { systemAction } from "../store/actions/systemAction";
import { useModal } from "../store/hooks/useModal";

type Props = {
  image: string;
};

const Girl: React.FC<Props> = React.memo(({ image }) => {
  const type = useSelector((state: AppState) => state.system.type);
  const finished = useSelector(
    (state: AppState) => state.system.progress.finished
  );
  const dispatch = useDispatch();
  const { open, close } = useModal();
  const handleVideoOpen = useCallback(() => {
    if (!finished && type === "girls") {
      dispatch(systemAction.updateCowntDown({ stop: true }));
      open("VIDEO");
      close("SELECTOR");
      // Send Event to Google Analytics
      ReactGA.event({
        category: "View",
        action: "Partial",
        label: "CLICK_GIRL_IMAGE",
      });
    }
  }, [type, finished, dispatch, open, close]);
  return (
    <div className="Girl" onClick={handleVideoOpen}>
      <img className="Girl-image" src={image} alt="Girl" />
    </div>
  );
});
export default Girl;
