import React, { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import ReactGA from "../Analytics";
import TwitterLogo from "../image/twitterLogo.svg";
import { AppState } from "../store";
import { useModal } from "../store/hooks/useModal";
import { ModalState } from "../store/types/systemTypes";
import { PrizeMessage } from "../templates/App";
import { BORDER_NUM } from "../templates/Score";

const Share: React.FC = React.memo(() => {
  const { correct, questions } = useSelector((state: AppState) => state.score);
  const display = useSelector((state: AppState) =>
    state.system.modal.some((obj: ModalState) => obj.modalKey === "SHARE")
  );
  const { message } = useContext(PrizeMessage);
  const close = useModal().close;
  const handleClose = useCallback(() => {
    close("SHARE");
  }, [close]);
  const handleShare = useCallback(() => {
    const ratio =
      questions === 0 ? 0 : Math.round((correct / questions) * 100 * 100) / 100;
    const prize = questions >= BORDER_NUM ? `%0a「${message}」と言われた` : "";
    const url = `https://twitter.com/share?url=https://kiminonaha.net&text=セクシー俳優名前当てゲームをしたよ！${prize}%0a%0a${questions}問答えて${correct}問%20正解%0a正解率%20${ratio}％%0a%0a%23名前当て%20%23無料ゲーム%20%23きみのなは%0a`;
    window.open(url, "_blank", "noopener");
    //Send Event to Google Analytics
    ReactGA.event({
      category: "Share",
      action: "Twitter",
    });
  }, [correct, questions, message]);
  return (
    <div
      className={`Share ${display ? "is-display" : ""}`}
      onClick={handleClose}
    >
      <div className="Share-container">
        <img src={TwitterLogo} alt="Share with Twitter" onClick={handleShare} />
        <button className="Share-button" onClick={handleShare}>
          Twitterで結果をつぶやく
        </button>
      </div>
    </div>
  );
});
export default Share;
