import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { systemAction } from "../store/actions/systemAction";
import { useModal } from "../store/hooks/useModal";
import { ModalState } from "../store/types/systemTypes";

type Props = {
  videoId: string;
};

const GirlVideo: React.FC<Props> = React.memo(({ videoId }) => {
  const display = useSelector((state: AppState) =>
    state.system.modal.some((obj: ModalState) => obj.modalKey === "VIDEO")
  );
  const dispatch = useDispatch();
  const close = useModal().close;
  const [key, setKey] = useState(0);
  const handleCloseVideo = useCallback(() => {
    close("VIDEO");
    dispatch(systemAction.updateCowntDown({ stop: false }));
    setKey(key + 1);
  }, [key, close, dispatch]);
  return (
    <div
      className={`GirlVideo ${display ? "is-display" : ""}`}
      onClick={handleCloseVideo}
    >
      <div className="GirlVideo-inner">
        <iframe
          key={key}
          className="GirlVideo-iframe"
          title="Video"
          src={`https://www.dmm.co.jp/litevideo/-/part/=/affi_id=whatyourname-001/cid=${videoId}/size=476_306/`}
          scrolling="no"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
});

export default GirlVideo;
