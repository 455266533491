import { Moment } from "moment";

export type Girl = {
  girlId: number;
  girlName: string;
  timestamp: Moment;
};

export type ScoreState = {
  correct: number;
  questions: number;
  girls: Girl[];
};

export const initialScoreState: ScoreState = {
  correct: 0,
  questions: 0,
  girls: [],
};
