import React, { useCallback, useEffect } from "react";
import Yes from "../image/yes.svg";
import No from "../image/no.svg";
import { useDispatch, useSelector } from "react-redux";
import { scoreActions } from "../store/actions/scoreAction";
import moment from "moment";
import { Girl } from "../store/types/scoreTypes";
import { AppState } from "../store";
import { ModalState } from "../store/types/systemTypes";
import { useModal } from "../store/hooks/useModal";
import ReactGA from "../Analytics";

type Props = {
  correct: boolean;
  handleChange: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void | undefined;
  correctId: number;
  correctName: string;
};

const Result: React.FC<Props> = React.memo(
  ({ correct, handleChange, correctId, correctName }) => {
    const type = useSelector((state: AppState) => state.system.type);
    const display = useSelector((state: AppState) =>
      state.system.modal.some((obj: ModalState) => obj.modalKey === "RESULT")
    );
    const correctNumber = useSelector((state: AppState) => state.score.correct);
    const { reseted, finished } = useSelector(
      (state: AppState) => state.system.progress
    );
    const dispatch = useDispatch();

    const { open, close } = useModal();

    useEffect(() => {
      if (!finished) {
        return;
      }
      if (finished && !reseted) {
        open("RESULT");
      }
      if (finished && reseted) {
        close("RESULT");
      }
    }, [finished, reseted, open, close]);

    const addGirlToScore = useCallback(() => {
      const now = moment(new Date());
      const girl: Girl = {
        girlId: correctId,
        girlName: correctName,
        timestamp: now,
      };
      dispatch(scoreActions.addScore(girl));
      //Send Result to Google Analytics;
      ReactGA.event({
        category: "Score",
        action: type === "girls" ? "GirlName" : "BoyName",
        label: correctName,
      });
    }, [type, correctId, correctName, dispatch]);

    useEffect(() => {
      if (finished && correct) {
        addGirlToScore();
      }
    }, [finished, correct, addGirlToScore]);
    useEffect(() => {
      if (finished && correct && reseted) {
        dispatch(scoreActions.updateCorrect(correctNumber + 1));
      }
    }, [reseted, finished, correct, dispatch, correctNumber]);
    return (
      <div
        className={`Result ${correct ? "is-correct" : "is-not-correct"} ${
          display ? "is-display" : ""
        }`}
      >
        <img
          className="Result-image"
          src={correct ? Yes : No}
          alt={correct ? "yes" : "no"}
        />
        <div className="Result-text">
          <span className="Result-text-en">
            {correct ? "Congraturations!" : "Oops! That’s not correct!"}
          </span>
          <span className="Result-text-ja">
            {correct
              ? "正解！君はすごいね！"
              : `残念！${correctName}だよ。覚えてね。`}
          </span>
        </div>
        <div className="Result-button" onClick={handleChange}>
          Change?
        </div>
      </div>
    );
  }
);
export default Result;
