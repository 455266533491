import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { scoreActions } from "../store/actions/scoreAction";
import { systemAction } from "../store/actions/systemAction";
import { COUNT_DOWN_TIME } from "../templates/App";
import HeaderTitle from "./HeaderTitle";
import Pagenation from "./Pagenation";
import ProgressCircle from "./ProgressCircle";

// type Props = {
//   reseted: boolean;
// };

const Progress: React.FC = React.memo(() => {
  const stop = useSelector((state: AppState) => state.system.countDown.stop);
  const { reseted } = useSelector((state: AppState) => state.system.progress);
  const time = useSelector((state: AppState) => state.system.progressTime);
  const questions = useSelector((state: AppState) => state.score.questions);

  const dispatch = useDispatch();

  const [progressBarWidth, setProgressBarWidth] = useState(0);

  const stopTimer = useCallback(
    (timerId, now) => {
      dispatch(systemAction.updateProgressTime(now));
      clearInterval(timerId);
    },
    [dispatch]
  );

  useEffect(() => {
    if (time <= 0) {
      dispatch(systemAction.updateProgressFnished(true));
      return;
    }
    const timerId = setInterval(() => {
      const step = COUNT_DOWN_TIME - time + 1;
      setProgressBarWidth(() => (100 / COUNT_DOWN_TIME) * step);
      dispatch(systemAction.updateProgressTime(time - 1));
    }, 1000);
    if (stop) {
      stopTimer(timerId, time);
      return;
    }
    return () => clearInterval(timerId);
  }, [time, stop, stopTimer, dispatch]);

  useEffect(() => {
    if (reseted) {
      dispatch(systemAction.updateProgressTime(COUNT_DOWN_TIME));
      dispatch(systemAction.updateProgressFnished(false));
      dispatch(scoreActions.updateQuestions(questions + 1));
    }
  }, [reseted, dispatch, questions]);

  return (
    <div className="Progress">
      <div
        className="Progress-bar"
        style={{ width: `${progressBarWidth}%` }}
      ></div>
      <header className="Header">
        <Pagenation />
        <HeaderTitle />
        <ProgressCircle />
      </header>
    </div>
  );
});
export default Progress;
