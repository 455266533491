import React, { useCallback } from "react";
import Star from "../image/star.svg";
import { useDispatch, useSelector } from "react-redux";
import { systemAction } from "../store/actions/systemAction";
import { AppState } from "../store";

const Pagenation: React.FC = React.memo(() => {
  const stop = useSelector((state: AppState) => state.system.countDown.stop);
  const dispatch = useDispatch();
  const handleStop = useCallback(() => {
    dispatch(systemAction.updateCowntDown({ stop: !stop }));
  }, [stop, dispatch]);
  const handlePagenate = useCallback(() => {
    dispatch(
      systemAction.updateModal({ modal: { modalKey: "SCORE" }, add: true })
    );
    handleStop();
  }, [handleStop, dispatch]);
  return (
    <div className="Pagenation" onClick={handlePagenate}>
      <img className="Pagenation-image" src={Star} alt="Go to Score" />
      <span className="Pagenation-title">SCORE</span>
    </div>
  );
});
export default Pagenation;
