import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { Girl } from "../store/types/scoreTypes";
import ShareButton from "../image/share.svg";

import resultMessage from "../data/resultMessage.json";
import scoreMessage from "../data/scoreMessage.json";
import resultMessageForGirl from "../data/resultMessageForGirl.json";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ModalState } from "../store/types/systemTypes";
import { systemAction } from "../store/actions/systemAction";
import { useModal } from "../store/hooks/useModal";
import ReactGA from "../Analytics";
import { PrizeMessage } from "./App";

type Message = {
  point: number;
  message: string;
};

export const BORDER_NUM = 10;

const Score: React.FC = React.memo(() => {
  const type = useSelector((state: AppState) => state.system.type);
  const display = useSelector((state: AppState) =>
    state.system.modal.some((obj: ModalState) => obj.modalKey === "SCORE")
  );
  const stop = useSelector((state: AppState) => state.system.countDown.stop);
  const displayShare = useSelector((state: AppState) =>
    state.system.modal.some((obj: ModalState) => obj.modalKey === "SHARE")
  );
  const dispatch = useDispatch();

  const { setMessage } = useContext(PrizeMessage);

  const { open, close } = useModal();
  const handleClose = useCallback(() => {
    close("SCORE");
    dispatch(systemAction.updateCowntDown({ stop: !stop }));
  }, [stop, dispatch, close]);
  const girls = useSelector((state: AppState) => state.score.girls);

  const girlImage = useCallback(
    (girlId) => {
      return `${process.env.PUBLIC_URL}/image/${type}/${girlId}.jpg`;
    },
    [type]
  );
  const questions = useSelector((state: AppState) => state.score.questions);
  const correct = useSelector((state: AppState) => state.score.correct);

  const getMessage = useCallback((textArray: Message[], ratio_num: number) => {
    const textPointArray: number[] = textArray
      .map((obj: Message) => obj.point)
      .sort((a, b) => a - b);
    const resultNumberArray = textPointArray.filter((value: number, index) =>
      index === 0
        ? 0 <= ratio_num && ratio_num <= value
        : textPointArray[index - 1] <= ratio_num && ratio_num <= value
    );
    return textArray.filter(
      (obj) => obj.point === Number(resultNumberArray[0])
    )[0].message;
  }, []);
  const message = useMemo(() => {
    const ratio =
      correct === 0 && questions === 0 ? 0 : (correct / questions) * 100;
    const resultMessageData =
      type === "girls" ? resultMessage : resultMessageForGirl;
    const result =
      questions >= BORDER_NUM
        ? getMessage(resultMessageData, ratio)
        : getMessage(scoreMessage, ratio);

    return result;
  }, [type, correct, questions, getMessage]);

  useEffect(() => {
    setMessage(message);
  }, [message, setMessage]);

  const handleOpenShare = useCallback(() => {
    open("SHARE");
  }, [open]);

  const handleMovieLink = useCallback(
    (girlName: string, vr: boolean = false) => {
      const url = vr
        ? `https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Flist%2Fsearch%2F%3D%2Fdevice%3Dvr%2F%3Fsearchstr%3D${encodeURI(
            encodeURI(girlName)
          )}&af_id=whatyourname-001&ch=toolbar&ch_id=text`
        : `https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2F-%2Flist%2Fsearch%2F%3D%2F%3Fsearchstr%3D${encodeURI(
            encodeURI(girlName)
          )}&af_id=whatyourname-001&ch=toolbar&ch_id=text`;
      // const boysId = "APN0075962";
      // const urlForGirl = `https//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3553585&pid=886757700&vc_url=https%3A%2F%2Fvideo.hnext.jp%2Fbrowse%2Factor%2F${boysId}%3Fcid%3DD33139%26adid%3DVC1%26alp%3D1%26alpad%3D1%26alpge%3D1`;
      // const linkUrl = type === "girls" ? url : urlForGirl;
      window.open(url, "_blank", "noopener");
      //Send Event to Google Analytics
      ReactGA.event({
        category: "Link",
        action: vr ? "VR" : "MOVIE",
        label: girlName,
      });
    },
    []
  );
  return (
    <div
      className={`Score ${display ? "is-open-score" : ""} ${
        displayShare ? "is-open-share" : ""
      }`}
    >
      <div className="Score-inner">
        <div className="Score-header">
          <h2 className="Score-title">SCORE</h2>
          <p className="Score-message">{message}</p>
        </div>
        <div className="Score-result">
          <span className="Score-result-correct">{correct}</span>
          <span className="Score-result-questions"> / {questions}</span>
          <CircularProgressbar
            value={(correct / questions) * 100}
            styles={{
              path: { stroke: `rgba(20, 84, 30, ${correct / questions})` },
              trail: { stroke: "#ffffff" },
            }}
          />
          <div className="Score-result-share" onClick={handleOpenShare}>
            <img src={ShareButton} alt="Share" />
          </div>
        </div>
        <ul className="Score-list">
          {girls &&
            girls.map((girl: Girl, index: number) => {
              return (
                <li className="Score-list-item" key={index}>
                  <img
                    className="Score-list-item-image"
                    src={girlImage(girl.girlId)}
                    alt={girl.girlName}
                  />
                  <span
                    onClick={() => handleMovieLink(girl.girlName)}
                    className="Score-list-item-name"
                  >
                    {girl.girlName}
                  </span>
                  <span
                    className="Score-list-item-link"
                    onClick={() => handleMovieLink(girl.girlName)}
                  >
                    動画
                  </span>
                  <span
                    className="Score-list-item-link vr"
                    onClick={() => handleMovieLink(girl.girlName, true)}
                  >
                    VR
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="Score-close">
        <span className="Score-close-inner" onClick={handleClose}>
          CLOSE
        </span>
      </div>
    </div>
  );
});

export default Score;
