import { reducerWithInitialState } from "typescript-fsa-reducers";
import { systemAction } from "../actions/systemAction";
import {
  CountDownState,
  initialSystemState,
  ModalState,
  TypeKey,
} from "../types/systemTypes";

export const systemReducer = reducerWithInitialState(initialSystemState)
  .case(systemAction.updateType, (state, payload: TypeKey) => {
    return Object.assign({}, state, { type: payload });
  })
  .case(
    systemAction.updateModal,
    (state, payload: { modal: ModalState; add: boolean }) => {
      const isExistModal = state.modal.some(
        (obj: ModalState) => obj.modalKey === payload.modal.modalKey
      );
      if (payload.add) {
        if (isExistModal) {
          return state;
        }
        return Object.assign({}, state, {
          modal: [...state.modal, payload.modal],
        });
      } else {
        const reduceModal: ModalState[] = state.modal.filter(
          (obj: ModalState) => obj.modalKey !== payload.modal.modalKey
        );
        return Object.assign({}, state, {
          modal: reduceModal,
        });
      }
    }
  )
  .case(systemAction.updateCowntDown, (state, payload: CountDownState) => {
    return Object.assign({}, state, { countDown: payload });
  })
  .case(systemAction.updateProgressTime, (state, payload: number) => {
    return Object.assign({}, state, { progressTime: payload });
  })
  .case(systemAction.updateProgressFnished, (state, payload: boolean) => {
    const progress = Object.assign({}, state.progress, { finished: payload });
    return Object.assign({}, state, { progress: progress });
  })
  .case(systemAction.updateProgressReseted, (state, payload: boolean) => {
    const progress = Object.assign({}, state.progress, { reseted: payload });
    return Object.assign({}, state, { progress: progress });
  });
