import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "../Analytics";
import SelectType from "../components/SelectType";
import { AppState } from "../store";
import { systemAction } from "../store/actions/systemAction";
import { useModal } from "../store/hooks/useModal";
import { ModalState } from "../store/types/systemTypes";

const Cover: React.FC = React.memo(() => {
  const type = useSelector((state: AppState) => state.system.type);
  const display = useSelector((state: AppState) =>
    state.system.modal.some((obj: ModalState) => obj.modalKey === "COVER")
  );
  const dispatch = useDispatch();

  const close = useModal().close;

  const handleStart = useCallback(() => {
    close("COVER");
    dispatch(systemAction.updateCowntDown({ stop: false }));
    //Send Event to Google Analytics
    ReactGA.event({
      category: "View",
      action: "Page",
      label: "Start",
    });
    ReactGA.event({
      category: "View",
      action: "Type",
      label: type === "girls" ? "girls" : "boys",
    });
  }, [type, close, dispatch]);

  const handleBreak = useCallback(() => {
    window.open("https://google.com", "_self", "noopener");
    //Send Event to Google Analytics
    ReactGA.event({
      category: "View",
      action: "Break Off",
      label: "from CoverPage",
    });
  }, []);

  return (
    <div
      className={`Cover ${display ? "" : "is-hidden"} ${
        type === "girls" ? "" : "is-boys"
      }`}
    >
      <div className="Cover-inner">
        <h1 className="Cover-title">きみのなは</h1>
        <p className="Cover-subTitle">
          AV{type === "girls" ? "女優" : "男優"}名前当てゲーム
        </p>
        <SelectType />
        <p className="Cover-desc">あなたは18歳以上ですか？</p>
        <p>
          このサイトには、18歳未満の方には
          <br />
          不適切な表現を含んでいます。
          <br />
          ここから先、18歳未満の方のアクセスは
          <br />
          固くお断りします。
        </p>
        <div className="Cover-selector">
          <span className="Cover-no" onClick={handleBreak}>
            いいえ
          </span>
          <div className="Cover-yes" onClick={handleStart}>
            はい
          </div>
        </div>
      </div>
    </div>
  );
});

export default Cover;
