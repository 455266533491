import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "../Analytics";
import { AppState } from "../store";
import { systemAction } from "../store/actions/systemAction";
import { useModal } from "../store/hooks/useModal";
import { ModalState } from "../store/types/systemTypes";
import { GirlName, girlObject, NUMBER_OF_CHOICES } from "../templates/App";
import LikeButton from "./Likebutton";

type Props = {
  data: girlObject[];
};

const Selector: React.FC<Props> = React.memo(({ data }) => {
  const type = useSelector((state: AppState) => state.system.type);
  const { reseted, finished } = useSelector(
    (state: AppState) => state.system.progress
  );
  const stop = useSelector((state: AppState) => state.system.countDown.stop);
  const display = useSelector((state: AppState) =>
    state.system.modal.some((obj: ModalState) => obj.modalKey === "SELECTOR")
  );
  const close = useModal().close;
  const dispatch = useDispatch();

  // シャッフル
  const [random, setRandom] = useState(0);
  useEffect(() => {
    setRandom(Math.random());
  }, [reseted]);

  const shuffleArray = useMemo(() => {
    const copy = data.map((obj: girlObject) => obj.id);
    return [...Array(NUMBER_OF_CHOICES)].map(
      () => copy.splice(Math.floor(random * copy.length), 1)[0]
    );
  }, [data, random]);

  const [number, setNumber] = useState(undefined);
  const [isDisplay, setIsDisplay] = useState(false);

  const handleDisplay = useCallback(() => {
    if (!finished) {
      setIsDisplay(!isDisplay);
      // Send Event to Google Analytics
      ReactGA.event({
        category: "View",
        action: "Partial",
        label: "UNDER_SELECTOR",
      });
    }
  }, [finished, isDisplay]);
  const { setSelectedName } = useContext(GirlName);
  const handleQuestionFinish = useCallback(() => {
    dispatch(systemAction.updateProgressFnished(true));
    dispatch(systemAction.updateCowntDown({ stop: true }));
  }, [dispatch]);
  const handleSelect = useCallback(
    (num, girl) => {
      const LIKE_ANIMATION_MILLISECOUND = 800;
      const selectAnswer = async () => {
        setNumber(num);
        setSelectedName(girl);
        setTimeout(() => {
          handleQuestionFinish();
        }, LIKE_ANIMATION_MILLISECOUND);
      };
      if (!finished) {
        selectAnswer();
      }
    },
    [finished, setSelectedName, handleQuestionFinish]
  );
  useEffect(() => {
    if (!stop && !finished && !reseted) {
      setIsDisplay(true);
    } else {
      setIsDisplay(display);
    }
  }, [finished, reseted, stop, display]);

  const handleReset = useCallback(() => {
    close("SELECTOR");
    setNumber(undefined);
    setSelectedName("");
  }, [setSelectedName, close]);

  useEffect(() => {
    if (reseted) {
      handleReset();
    }
  }, [reseted, handleReset]);
  return (
    <div
      className={`Selector ${isDisplay ? "is-display" : ""} ${
        stop ? "is-stop" : ""
      } ${type === "girls" ? "" : "is-boys"}`}
    >
      <div className="Selector-handle" onClick={handleDisplay}>
        <span className="Selector-handle-inner"></span>
      </div>
      <ul className="Selector-list">
        {shuffleArray.map((key: number) => {
          const girl = data.filter((obj: girlObject) => obj.id === key)[0];
          return (
            <li
              className="Selector-list-item"
              key={key}
              onClick={() => handleSelect(key, girl.name)}
            >
              <span className="Selector-list-item-name">{girl.name}</span>
              <LikeButton
                className="Selector-list-item-button"
                clicked={key === number}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
});
export default Selector;
