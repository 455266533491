import { COUNT_DOWN_TIME } from "../../templates/App";

export type TypeKey = "girls" | "boys";

export type ModalKey =
  | "COVER"
  | "SCORE"
  | "RESULT"
  | "VIDEO"
  | "SELECTOR"
  | "SHARE";

export type ModalState = {
  modalKey: ModalKey;
};

export type CountDownState = {
  stop: boolean;
};

export type ProgressState = {
  finished: boolean;
  reseted: boolean;
};

export type SystemState = {
  type: TypeKey;
  modal: ModalState[];
  countDown: CountDownState;
  progress: ProgressState;
  progressTime: number;
};

export const initialSystemState: SystemState = {
  type: "girls",
  modal: [{ modalKey: "COVER" }],
  countDown: { stop: true },
  progress: { finished: false, reseted: false },
  progressTime: COUNT_DOWN_TIME,
};
