import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "../Analytics";
import { AppState } from "../store";
import { systemAction } from "../store/actions/systemAction";
import { useModal } from "../store/hooks/useModal";

const Hint: React.FC = React.memo(() => {
  const type = useSelector((state: AppState) => state.system.type);
  const finished = useSelector(
    (state: AppState) => state.system.progress.finished
  );
  const dispatch = useDispatch();
  const { open, close } = useModal();
  const handleVideoOpen = useCallback(() => {
    if (!finished && type === "girls") {
      dispatch(systemAction.updateCowntDown({ stop: true }));
      open("VIDEO");
      close("SELECTOR");
      // Send Event to Google Analytics
      ReactGA.event({
        category: "View",
        action: "Partial",
        label: "CLICK_HINT",
      });
    }
  }, [type, finished, dispatch, open, close]);
  return (
    <div className="Hint">
      <div className="Hint-content" onClick={handleVideoOpen}>
        ヒント（無料サンプル動画）
      </div>
    </div>
  );
});
export default Hint;
