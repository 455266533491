import { actionCreatorFactory } from "typescript-fsa";
import { Girl } from "../types/scoreTypes";

const actionCreator = actionCreatorFactory();

export const scoreActions = {
  addScore: actionCreator<Girl>("ADD_SCORE"),
  updateQuestions: actionCreator<number>("UPDATE_QUESTIONS"),
  updateCorrect: actionCreator<number>("UPDATE_CORRECT"),
};
